import { TermItemDefinition } from '@/services/term'
import { defineStore } from 'pinia'
export const useSearchSapInvoiceStore = defineStore('searchSapInvoiceStore', {
  state: () => ({
    SapInvoice_subsidyAccount: null as string | null,
    SapInvoice_subsidyOrder: null as string | null,
    SapInvoice_ident: null as number | null,
    items: [
      { key: 'SapInvoice_subsidyAccount', operation: ':' },
      { key: 'SapInvoice_subsidyOrder', operation: ':' },
      { key: 'SapInvoice_ident', operation: ':' }
    ] as TermItemDefinition[]
  })
})
