<template>
  <div>
    <h2 v-html="$t('search_sap_invoice_title')"></h2>
    <!-- GL-Account -->
    <v-select
      id="search_sap_invoice_gl_account"
      v-model="searchCardStore.SapInvoice_subsidyAccount"
      :label="$t('search_sap_invoice_gl_account')"
      :items="accounts"
      :item-title="this.$getLangKey()"
      item-value="name"
      clearable
      @keyup.enter="search"
    />
    <!-- Order-ID -->
    <v-select
      id="search_sap_invoice_order_id"
      v-model="searchCardStore.SapInvoice_subsidyOrder"
      :label="$t('search_sap_invoice_order_id')"
      :items="orders"
      :item-title="this.$getLangKey()"
      item-value="name"
      clearable
      @keyup.enter="search"
    />
    <!-- SAP Beleg-Nr -->
    <v-text-field
      id="search_sap_invoice_sap_beleg_nr"
      v-model="searchCardStore.SapInvoice_ident"
      :label="$t('search_sap_invoice_sap_beleg_nr')"
      type="text"
      @keyup.enter="search"
    />
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import { useSubsidyAccountsStore } from '@/store/enums/SubsidyAccountsStore'
import { useSubsidyOrdersStore } from '@/store/enums/SubsidyOrdersStore'
import { useSearchSapInvoiceStore } from '@/store/SearchSapInvoiceStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchSapInvoice',
  extends: baseSearchVue,
  props: {
    subsidyType: { type: String, default: '', required: false }
  },
  data() {
    return {}
  },
  computed: {
    subsidyAccounts() {
      return useSubsidyAccountsStore().subsidyAccounts
    },
    subsidyAccountsProduction() {
      return useSubsidyAccountsStore().subsidyAccountsProduction
    },
    subsidyAccountsProcessing() {
      return useSubsidyAccountsStore().subsidyAccountsProcessing
    },
    subsidyOrders() {
      useSubsidyOrdersStore().subsidyOrders
    },
    subsidyOrdersProduction() {
      useSubsidyOrdersStore().subsidyOrdersProduction
    },
    subsidyOrdersProcessing() {
      useSubsidyOrdersStore().subsidyOrdersProcessing
    },
    accounts() {
      if (this.subsidyType === 'PRODUCTION') {
        return this.subsidyAccountsProduction
      } else if (this.subsidyType === 'PROCESSING') {
        return this.subsidyAccountsProcessing
      } else {
        return this.subsidyAccounts
      }
    },
    orders() {
      if (this.subsidyType === 'PRODUCTION') {
        return this.subsidyOrdersProduction
      } else if (this.subsidyType === 'PROCESSING') {
        return this.subsidyOrdersProcessing
      } else {
        return this.subsidyOrders
      }
    }
  },
  methods: {
    _orders() {
      if (this.subsidyType === 'PRODUCTION') {
        console.log(this.subsidyType, this.subsidyOrdersProduction())
        return this.subsidyOrdersProduction()
      } else if (this.subsidyType === 'PROCESSING') {
        console.log(this.subsidyType, this.subsidyOrdersProcessing())
        return this.subsidyOrdersProcessing()
      } else {
        console.log(this.subsidyType, this.subsidyOrders())
        return this.subsidyOrders()
      }
    }
  },
  beforeMount() {
    useSubsidyAccountsStore().fetchSubsidyAccounts()
    useSubsidyAccountsStore().fetchSubsidyAccountsProduction()
    useSubsidyAccountsStore().fetchSubsidyAccountsProcessing()
    useSubsidyOrdersStore().fetchSubsidyOrders()
    useSubsidyOrdersStore().fetchSubsidyOrdersProduction()
    useSubsidyOrdersStore().fetchSubsidyOrdersProcessing()
    this.setSearchCardStore(useSearchSapInvoiceStore(), true)
  }
})
</script>
