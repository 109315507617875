//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import { app } from '@/main'
import { showError } from '@/services/axios'
import { defineStore } from 'pinia'
export const useSubsidyOrdersStore = defineStore('subsidyOrdersStore', {
  state: () => ({
    subsidyOrders: [] as any[],
    subsidyOrdersProduction: [] as any[],
    subsidyOrdersProcessing: [] as any[]
  }),
  actions: {
    async fetchSubsidyOrders() {
      if (this.subsidyOrders.length === 0) {
        try {
          const result = await app.axios.get(apiURL + '/subsidyOrders')
          this.subsidyOrders = result.data
        } catch (e) {
          showError(e)
        }
      }
    },
    async fetchSubsidyOrdersProduction() {
      if (this.subsidyOrdersProduction.length === 0) {
        try {
          const result = await app.axios.get(apiURL + '/subsidyOrders?subset=MARKETEDMILK')
          this.subsidyOrdersProduction = result.data
        } catch (e) {
          showError(e)
        }
      }
    },
    async fetchSubsidyOrdersProcessing() {
      if (this.subsidyOrdersProcessing.length === 0) {
        try {
          const result = await app.axios.get(apiURL + '/subsidyOrders?includeVirtual=true')
          this.subsidyOrdersProcessing = result.data
        } catch (e) {
          showError(e)
        }
      }
    }
  }
})
