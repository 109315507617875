//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import { app } from '@/main'
import { showError } from '@/services/axios'
import { defineStore } from 'pinia'
export const useSubsidyAccountsStore = defineStore('subsidyAccountsStore', {
  state: () => ({
    subsidyAccounts: [] as any[],
    subsidyAccountsProduction: [] as any[],
    subsidyAccountsProcessing: [] as any[]
  }),
  actions: {
    async fetchSubsidyAccounts() {
      if (this.subsidyAccounts.length === 0) {
        try {
          const result = await app.axios.get(apiURL + '/subsidyAccounts')
          this.subsidyAccounts = result.data
        } catch (e) {
          showError(e)
        }
      }
    },
    async fetchSubsidyAccountsProduction() {
      if (this.subsidyAccountsProduction.length === 0) {
        try {
          const result = await app.axios.get(apiURL + '/subsidyAccounts?subset=SUBSIDY')
          this.subsidyAccountsProduction = result.data
        } catch (e) {
          showError(e)
        }
      }
    },
    async fetchSubsidyAccountsProcessing() {
      if (this.subsidyAccountsProcessing.length === 0) {
        try {
          const result = await app.axios.get(apiURL + '/subsidyAccounts?includeVirtual=true')
          this.subsidyAccountsProcessing = result.data
        } catch (e) {
          showError(e)
        }
      }
    }
  }
})
